import React from "react";
import "./HomepageIntro.scss";
import { ReactComponent as ArrowDownIcon } from "./../../../assets/icons/svg/arrow-down.svg";
import Button from "../../reusable/Button/Button";
import Header from "./../Header/Header"

export default function HomepageIntro(props) {

    const { data } = props;

    return (
        <div className="container-homepage">
            <img className="img-background" style={{ height: window.innerHeight }} src={`https://api.thefootballbrain.app/uploads/competition/header_image/${data.comp_details.id_competition}.jpg`} />
            <Header {...props} />
            <div className="intro-content-container">
                <div className="title" style={{ color: data.config_options.header_color }}>{data.comp_details.competition_name}</div>
                <div className="description" style={{ color: data.config_options.header_color }}>{data.comp_details.description_competition}</div>
                <div className="button-row">
                    <Button
                        onClick={() => {
                            props.scrollToMatches();
                        }}
                        theme={props.config_options.id_theme}
                        color={props.config_options.buttons_color}
                        backgroundcolor={props.config_options.buttons_text_color}
                    >
                        {"VEZI PROGRAM MECIURI "}
                        <ArrowDownIcon
                            fill={props.config_options.buttons_color}
                            className="discover-more-button-icon"
                        />
                    </Button>
                </div>
            </div>
        </div>
    )
}