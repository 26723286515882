import React from "react";
import "./RegisteredTeamsWidget.scss";
import { ReactComponent as LeftArrow } from "../../../assets/icons/svg/arrow-left.svg";
import { ReactComponent as RightArrow } from "../../../assets/icons/svg/arrow-right.svg";
import { css } from "@emotion/css";
import RegisteredTeamElement from "./RegisteredTeamElement";
import AliceCarousel from "react-alice-carousel";
import 'react-alice-carousel/lib/alice-carousel.css';

class RegisteredTeamsWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: props.selectedOption,
            items: [],
            teamsOptions: props.teamsOptions,
            all_data: props.data.all_data,
        }
    };

    render() {

        const responsive = {
            0: { items: 1 },
            320: { items: 2 },
            630: { items: 3 },
            1024: { items: 4 },
            1280: { items: 4 },
            1680: { items: 5 },
        };

        return (
            <div className="teams-widget-container">
                <div className="teams-widget-title">{"Echipe înscrise"}</div>
                <div className="teams-widget-meniu">
                    {this.state.teamsOptions.map((item, index) => {
                        return (
                            <div
                                key={"team-" + index}
                                className={css`
                                color: ${item.value === this.props.selectedOption ? this.props.config_options.main_color : this.props.config_options.article_paragraph_color};
                                font-weight: ${item.value === this.props.selectedOption ? "bold" : "normal"} 
                                `}
                                onClick={() => {
                                    this.props.handleSelectedOption(item.value);
                                    this.setState({ selectedOption: item.value })
                                }}
                            >
                                <div className="teams-widget-meniu-elem">
                                    {item.text}
                                    <div
                                        className="border-active"
                                        style={{
                                            borderBottom: item.value === this.props.selectedOption ? `5px solid ${this.props.main_color}` : '',
                                            paddingBottom: "5px",
                                        }}
                                    >
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="teams-widget-teams">
                    {this.state.all_data.comp_age_groups.map((elem, index) => {
                        return elem.id_competition == this.props.selectedOption ?
                            <div key={"alice-carousel-" + index}>
                                <div className="carousel-container">
                                    <AliceCarousel
                                        mouseTracking
                                        disableDotsControls
                                        disableButtonsControls
                                        responsive={responsive}
                                        ref={(el) => (this.Carousel = el)}
                                    >
                                        {elem.teams_table.map((item, index2) => {
                                            return (
                                                <RegisteredTeamElement
                                                    key={"team-element-" + index + "-" + index2}
                                                    team_logo={`https://api.thefootballbrain.app/uploads/team_logos/${item.team_logo}`}
                                                    team_name={item.team_name}
                                                />
                                            );
                                        })}
                                    </AliceCarousel>
                                </div>
                                <div className="groups-homepage-carousel-buttons">
                                    <div
                                        className="prev-button-desktop-teams"
                                        style={{
                                            borderRadius:
                                                this.props.config_options.id_theme == 2
                                                    ? "40px 0px 0px 40px"
                                                    : "",
                                            backgroundColor: this.props.config_options.main_color,
                                        }}
                                        onClick={() => this.Carousel.slidePrev()}
                                    >
                                        <LeftArrow
                                            className="prev-button-img"
                                            fill={this.props.config_options.play_icon_color}
                                        />
                                    </div>
                                    <div
                                        onClick={() => this.Carousel.slideNext()}
                                        className="next-button-desktop-teams"
                                        style={{
                                            borderRadius:
                                                this.props.config_options.id_theme == 2
                                                    ? "0px 40px 40px 0px"
                                                    : "",
                                            backgroundColor: this.props.config_options.main_color,
                                        }}
                                    >
                                        <RightArrow
                                            className="next-button-img"
                                            fill={this.props.config_options.play_icon_color}
                                        />
                                    </div>
                                </div>
                            </div> :
                            <div key={"alice-carousel-" + index}>
                            </div>
                    })}
                </div>
            </div>
        )
    }
}

export default RegisteredTeamsWidget;