import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/svg/close.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/svg/arrow-down.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icons/svg/arrow-right.svg";
import { ReactComponent as MeniuMobile } from "../../../assets/icons/svg/menu.svg";
import { NavLink } from "react-router-dom";
import "./Header.scss"

export default function Header(props) {

    const menuOptions = [
        {
            text: "Acasă",
            subtitles: false,
            key: "home",
            path: "/",
            scrollable: false,
        },
        {
            text: "Regulament",
            subtitles: false,
            key: "rules",
            path: "/rules",
            scrollable: false,
        },
        {
            text: "Înscrieri",
            subtitles: false,
            key: "registration",
            path: "/registration",
            scrollable: false,
        },
        {
            text: "Meciuri",
            subtitles: false,
            key: "matches",
            path: "/matches",
            scrollable: false,
        },
        {
            text: "Clasament",
            subtitles: false,
            key: "ranking",
            path: "/ranking",
            scrollable: false,
        },
        {
            text: "Parteneri",
            subtitles: false,
            key: "partners",
            path: "/partners",
            scrollable: false,
        }
    ];

    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [stickyMenu, setStickyMeniu] = useState(false);
    const [section, setSection] = useState("home");

    const changeScroll = () => {
        if (window.scrollY >= window.innerHeight) {
            setStickyMeniu(true);
        } else {
            setStickyMeniu(false);
        }
    }
    window.addEventListener('scroll', changeScroll);

    return (
        <div className={stickyMenu ? "header-container header-container-on-scroll" : "header-container"}>
            <div
                className={stickyMenu ? "menu-header menu-header-on-scroll" : "menu-header"}
                style={{
                    backgroundColor: props.config_options.main_color,
                    borderRadius: props.theme === "2" ? 10 : 0,
                }}
            >

                <div className="menu-options">
                    {menuOptions.map((item, index) => {
                        return (
                            index === menuOptions.length / 2 ?
                                <NavLink
                                    to="/"
                                    style={{ textDecoration: "none", color: "black" }}
                                    className="badge-name"
                                    key={"menu-desktop-item-" + index}
                                    onClick={() => window.location.reload(false)}
                                >
                                    <img
                                        src={`https://api.thefootballbrain.app/uploads/competition/logo/${props.config_options.id_competition}.png`}
                                        alt="logo"
                                        className="mobile-logo"
                                    />
                                </NavLink> :
                                <div
                                    key={"menu-desktop-item-" + index}
                                    onClick={() => {
                                        switch (item.key) {
                                            case "registration":
                                                props.scrollToRegistration();
                                                setSection("registration")
                                                break;
                                            case "rules":
                                                props.scrollToRules();
                                                setSection("rules")
                                                break;
                                            case "home":
                                                props.scrollToHome();
                                                setSection("home");
                                                break;
                                            case "matches":
                                                props.scrollToMatches();
                                                setSection("matches");
                                                break;
                                            case "ranking":
                                                props.scrollToRanking();
                                                setSection("ranking");
                                                break;
                                            case "partners":
                                                props.scrollToPartners();
                                                setSection("partners")
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                    style={{
                                        textDecoration: "none",
                                        color: props.textColor,
                                    }}
                                >
                                    <div className="menu-option">
                                        <div className="menu-option-text">
                                            {item.text}
                                            <div
                                                className="border-active"
                                                style={{
                                                    borderBottom: `5px solid ${item.key === section ? props.config_options.clock_lines_icon_color : 'white'}`,
                                                    paddingBottom: "5px",
                                                    width: `${item.key === section ? '100%' : '20px'}`,
                                                    transition: "width 0.5s"
                                                }}
                                            >
                                            </div>
                                            {item.subtitles && (
                                                <ArrowDown className="arrow-down-menu" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                        );
                    })}
                </div>
                <div className="menu-mobile">
                    <img
                        src={`https://api.thefootballbrain.app/uploads/competition/logo/${props.config_options.id_competition}.png`}
                        alt="logo"
                        className="mobile-logo"
                        onClick={() => window.location.reload(false)}
                    />
                    {!mobileMenuActive ?
                        <MeniuMobile
                            className="menu-mobile-img"
                            fill={props.config_options.clock_lines_icon_color}
                            alt=""
                            onClick={() => setMobileMenuActive(true)}
                        /> :
                        <CloseIcon
                            fill={props.config_options.clock_lines_icon_color}
                            className="menu-mobile-img"
                            style={{ zIndex: "33333" }}
                            onClick={() => setMobileMenuActive(false)}
                        />

                    }
                </div>
            </div>
            {mobileMenuActive &&
                <div
                    className="menu-mobile-content"
                    style={{
                        backgroundColor: props.config_options.main_color,
                        borderRadius: props.theme === "2" ? 10 : 0,
                    }}
                >
                    {menuOptions.map((item, index) => {
                        return (
                            <div
                                key={"menu-mobile-item-" + index}
                                style={{
                                    textDecoration: "none",
                                    color: "white",
                                }}
                                onClick={() => {
                                    setMobileMenuActive(false)
                                    switch (item.key) {
                                        case "registration":
                                            props.scrollToRegistration();
                                            setSection("registration")
                                            break;
                                        case "rules":
                                            props.scrollToRules();
                                            setSection("rules")
                                            break;
                                        case "home":
                                            props.scrollToHome();
                                            setSection("home");
                                            break;
                                        case "matches":
                                            props.scrollToMatches();
                                            setSection("matches");
                                            break;
                                        case "ranking":
                                            props.scrollToRanking();
                                            setSection("ranking");
                                            break;
                                        case "partners":
                                            props.scrollToPartners();
                                            setSection("partners")
                                            break;
                                        default:
                                            break;
                                    }
                                }}
                            >
                                <div
                                    className="mobile-menu-option"
                                    style={{
                                        borderBottom: `solid 1px #404040`,
                                    }}
                                >
                                    <div className="option-content">
                                        {item.text}
                                        <ArrowRight
                                            className="menu-mobile-goto"
                                            fill={props.config_options.clock_lines_icon_color}
                                        />
                                    </div>
                                    <div
                                        className="border-active"
                                        style={{
                                            borderBottom: `4px solid ${item.key === section ? props.config_options.clock_lines_icon_color : 'white'}`,
                                            width: `${item.key === section ? '30%' : '20px'}`
                                        }}
                                    ></div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            }
        </div >
    )
}