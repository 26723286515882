import React from "react";
import "./Select.scss";
import Select from "react-select"

const SelectCustom = ({ handleChange, label, ...otherSelectProps }) => (
    <div
        className={
            otherSelectProps.halfWidth ? "group half-width" : (otherSelectProps.thirdWidth ? "group third-width" : "group full-width")
        }
    >
        <Select
            className="form-input-select"
            styles={{ borderBottom: "none" }}
            onChange={handleChange}
            {...otherSelectProps}
        />
        {label ? (
            <label
                className={`${otherSelectProps.shrink ? "shrink" : ""
                    } form-input-label`}
            >
                {label}
            </label>
        ) : null}
    </div >
);

export default SelectCustom;