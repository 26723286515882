import React from "react";
import "./CompetitionRules.scss"

export default function CompetitionRules(props) {

    return (
        <div className="rules-container">
            <div className="rules-title" >
                {"Regulamentul competiției"}
            </div>
            <div className="rules-content">
                <div dangerouslySetInnerHTML={{ __html: props.comp_details.rules_competition }} />
            </div>
        </div>
    )
}