import React from "react";
import "./Button.scss";

const Button = ({ className, children, ...otherProps }) => (
  <button className={`custom-button ${className}`} {...otherProps}
    style={{
      borderRadius: otherProps.theme === "2" ? 40 : 0,
      backgroundColor: otherProps.backgroundcolor,
      color: otherProps.color
    }}>
    {children}
  </button>
);

export default Button;
