import React from "react";
import "./RegisteredTeamElement.scss"

export default function RegisteredTeamElement(props) {

    return (
        <div className="team-elem-container">
            <div className="team-logo">
                <img className="team-logo-img" src={props.team_logo}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "https://api.thefootballbrain.app/uploads/placeholder_team.png";
                    }}
                />
            </div>
            <div className="team-name">
                {props.team_name}
            </div>

        </div>
    )
}