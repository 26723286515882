import React from "react";
import "./Input.scss";

const Input = ({ handleChange, label, data, ...otherInputProps }) => (
  <div
    className={
      otherInputProps.halfWidth ? "group half-width" : (otherInputProps.thirdWidth ? "group third-width" : "group full-width")
    }
  >
    <input
      className={"form-input"}
      onChange={handleChange}
      {...otherInputProps}
    />
    {label ? (
      <label
        className={`${otherInputProps.value.length ? "shrink" : ""
          } form-input-label`}
      >
        {label}
      </label>
    ) : null}
  </div>
);

export default Input;
