import React from "react";
import './AboutCompetition.scss'
import { ReactComponent as ArrowDownIcon } from "./../../../assets/icons/svg/arrow-down.svg";
import Button from "../../reusable/Button/Button";

export default function AboutCompetition(props) {

    return (
        <div className="about-competition-container">
            <div className="about-title">
                {"Despre competiție"}
            </div>
            <div className="about-content"
                style={{
                    textAlign: "center"
                }}
            >
                <div dangerouslySetInnerHTML={{ __html: props.data.comp_details.about_competition }} />
            </div>
            <div className="about-read-more">
                <Button
                    onClick={() => {
                        props.scrollToRules();
                    }}
                    theme={props.data.config_options.id_theme}
                    backgroundcolor={props.data.config_options.main_color}
                    color={props.data.config_options.buttons_text_color}
                >
                    {"VEZI REGULAMENT "}
                    <ArrowDownIcon
                        fill={props.data.config_options.buttons_text_color}
                        className="discover-more-button-icon"
                    />
                </Button>
            </div>
        </div>
    )
}