import React from "react";
import SpecialPlayerCard from "./SpecialPlayerCard";
import "./SpecialPlayerCard.scss"

class SpecialPlayers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            player: {
                name: "Alexandru Alexandrescu",
                logo: "https://api.thefootballbrain.app/uploads/players/565.jpg",
                number: 10
            },
            prizes_types: {
                "Tournament top scorer": 1,
                "Tournament best player": 2,
                "Tournament best goalkeper": 3
            }
        }
    };

    render() {
        return (
            <div className="special-players-container">
                {this.props.prizes.map((elem, index) => {
                    return <SpecialPlayerCard {...this.props} typeOfCard={this.state.prizes_types[elem.prize_type]} player={elem} />
                })}
            </div>
        )
    }
}

export default SpecialPlayers;