import React from "react"
import "./LiveMatchesWidget.scss";

class LiveMatchesWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    };

    render() {

        return (
            <div className="live-widget-container">
                <div className="live-widget-title" >
                    Meciuri LIVE
                </div>
                <div className="live-table">
                    <div className="live-table-top-row" style={{ backgroundColor: this.props.config_options.main_color, color: this.props.config_options.background_color }}>
                        <div className="col day" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                            Categoria de varsta
                        </div>
                        <div className="col time" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                            Ora
                        </div>
                        <div className="col location" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                            Locatia
                        </div>
                        <div className="col match" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                            Meciul
                        </div>
                        <div className="col group">
                            Grupa
                        </div>
                    </div>
                    <div className="live-table-matches">
                        {this.props.all_data.live_matches.map((elem, index) => {
                            return (
                                <div className="live-match">
                                    <div className="col day" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                        {this.props.all_data.comp_age_groups.find(item => item.id_competition === elem.id_competition).competition_name}
                                    </div>
                                    <div className="col time" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                        {elem.match_date.split(" ")[1].substring(0, 5)}
                                    </div>
                                    <div className="col location" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                        {elem.venue_name}
                                    </div>
                                    <div className="col match" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                        <div className="first-team">
                                            <div className="logo-container" style={{ marginLeft: "5%" }}>
                                                <img className="team-logo" src={`https://api.thefootballbrain.app/uploads/team_logos/${elem.id_team_home}logo.png`}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src = "https://api.thefootballbrain.app/uploads/placeholder_team.png";
                                                    }}
                                                />
                                            </div>
                                            <div className="team-name">
                                                {elem.home_team_name}
                                            </div>
                                        </div>
                                        <div className="match-result">
                                            <div className="live-icon-container rec">
                                            </div>
                                            {(elem.team_home_goals == null || elem.team_away_goals == null) ? "0:0" : elem.team_home_goals + ":" + elem.team_away_goals}
                                        </div>
                                        <div className="second-team">
                                            <div className="team-name">
                                                {elem.away_team_name}
                                            </div>
                                            <div className="logo-container" style={{ marginRight: "5%" }}>
                                                <img className="team-logo" src={`https://api.thefootballbrain.app/uploads/team_logos/${elem.id_team_away}logo.png`}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src = "https://api.thefootballbrain.app/uploads/placeholder_team.png";
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {elem.id_competition_group > 0 && <div className="col group">
                                        <div className="group-circle" style={{ backgroundColor: this.props.config_options.main_color }}>
                                            <div className="group-letter" style={{ color: this.props.config_options.buttons_text_color }}>{elem.group_name}</div>
                                        </div>
                                    </div>}
                                    {elem.id_competition_group == 0 && elem.round.length > 0 && <div className="col after-group">
                                        {elem.round}
                                    </div>}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="live-table-mobile">
                    {this.props.all_data.live_matches.map((elem, index) => {
                        return <div className="live-match">
                            {elem.id_competition_group > 0 &&
                                <div className="group" style={{ color: this.props.config_options.buttons_text_color, backgroundColor: this.props.config_options.main_color }}>
                                    {"Grupa " + elem.group_name}
                                </div>
                            }
                            {elem.id_competition_group == 0 && elem.round.length > 0 &&
                                <div className="after-group" style={{ color: this.props.config_options.buttons_text_color, backgroundColor: this.props.config_options.main_color }}>
                                    {elem.round}
                                </div>
                            }
                            <div className="match-details">
                                <div className="first-team">
                                    <div className="team-name">
                                        {elem.home_team_name}
                                    </div>
                                    <div className="logo-container">
                                        <img className="team-logo" src={`https://api.thefootballbrain.app/uploads/team_logos/${elem.id_team_home}logo.png`}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = "https://api.thefootballbrain.app/uploads/placeholder_team.png";
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="match-result">
                                    <div className="live-icon-container rec">
                                    </div>
                                    {(elem.team_home_goals == null || elem.team_away_goals == null) ? "0:0" : elem.team_home_goals + ":" + elem.team_away_goals}
                                </div>
                                <div className="second-team">
                                    <div className="logo-container">
                                        <img className="team-logo" src={`https://api.thefootballbrain.app/uploads/team_logos/${elem.id_team_away}logo.png`}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = "https://api.thefootballbrain.app/uploads/placeholder_team.png";
                                            }}
                                        />
                                    </div>
                                    <div className="team-name">
                                        {elem.away_team_name}
                                    </div>
                                </div>
                            </div>
                            <div className="match-info">
                                <div className="category" >
                                    {this.props.all_data.comp_age_groups.find(item => item.id_competition === elem.id_competition).competition_name}
                                </div>
                                <div className="split-line" style={{ borderColor: `${this.props.config_options.article_paragraph_color}` }}></div>
                                <div className="time" >
                                    {elem.match_date.split(" ")[1].substring(0, 5)}
                                </div>
                                <div className="split-line" style={{ borderColor: `${this.props.config_options.article_paragraph_color}` }}></div>
                                <div className="location" >
                                    {elem.venue_name}
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        )
    }
}

export default LiveMatchesWidget;