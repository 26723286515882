import React, { useState } from "react";
import Button from "../../reusable/Button/Button";
import Input from "./../../reusable/Input/Input";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/svg/arrow-right.svg";
import "./RegistrationForm.scss"
import SelectCustom from "./../../reusable/Select/Select";
import judete from "./../../../assets/judete.json";
import axios from "axios";

export default function RegistrationForm(props) {

    const [academyName, setAcademyName] = useState("");
    const [county, setCounty] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");
    const [details, setDetails] = useState("");
    const [shrinkLabel, setShrinkLabel] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const [styleFocused1, setStyleFocused1] = useState({ 'border-bottom': `4px solid black` });
    const [styleFocused2, setStyleFocused2] = useState({ 'border-bottom': `4px solid black` });
    const [styleFocused3, setStyleFocused3] = useState({ 'border-bottom': `4px solid black` });
    const [styleFocused4, setStyleFocused4] = useState({ 'border-bottom': `4px solid black` });
    const [styleFocused5, setStyleFocused5] = useState({ 'border-bottom': `4px solid black` });


    const options = judete.judete;

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: props.config_options.background_color,
            borderStyle: "none",
            borderBottom: "solid 4px black",
            borderRadius: "0px",
            boxShadow: "none",
            width: "108%",
            "&:hover": {
                borderBottom: `solid 4px #dee0de`,
            },
            "@media only screen and (max-width: 480px)": {
                ...provided["@media only screen and (max-width: 480px)"],
                minHeight: "12px",
                borderBottom: "solid 2px black",
                "&:hover": {
                    borderBottom: "solid 2px black",
                },
            },
            "@media only screen and (min-width: 480px)": {
                ...provided["@media only screen and (min-width: 480px)"],
                minHeight: "12px",
                borderBottom: "solid 2px black",
                width: "106%",
                "&:hover": {
                    borderBottom: "solid 2px black",
                },
            },
            "@media only screen and (min-width: 768px)": {
                ...provided["@media only screen and (min-width: 768px)"],
                minHeight: "12px",
                borderBottom: "solid 4px black",
                width: "106%",
                "&:hover": {
                    borderBottom: "solid 4px black",
                },
            },
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            "@media only screen and (max-width: 480px)": {
                ...provided["@media only screen and (max-width: 480px)"],
                padding: "0 5px"
            },
            "&:hover": {
                padding: "0 5px"
            },
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
            "@media only screen and (max-width: 480px)": {
                ...provided["@media only screen and (max-width: 480px)"],
                paddingBottom: "0"
            },
            "&:hover": {
                paddingBottom: "0"
            },
        }),
        input: (provided, state) => ({
            ...provided,
            padding: "0",
            margin: "0"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "black"
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none"
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: "black",
            "&:hover": {
                color: "black",
            }
        }),

    }

    const sendForm = () => {
        if (academyName.length == 0 ||
            !county.label ||
            name.length == 0 ||
            email.length == 0 ||
            tel.length == 0
        ) {
            setError(true);
            setSuccess(false);
        } else {
            const payload = {
                team_name: academyName,
                team_county: county.label ? county.label : "",
                contact_person_name: name,
                contact_person_email: email,
                contact_person_tel: tel,
                aditional_details: details,
                send_to_email: props.comp_details.competition_email,
                // send_to_email: "alexandrina.jechiu@bweb.ro",
                competition_name: props.comp_details.competition_name,
                id_club: props.comp_details.id_club,
            };
            axios
                .post("site/competition_registration_form", payload)
                .then((res) => {
                    setAcademyName("");
                    setCounty("");
                    setName("");
                    setEmail("");
                    setTel("");
                    setDetails("");
                    setError(false);
                    setSuccess(true);
                })
                .catch((err) => console.log(err));
        }
    }

    return (
        <div className="registration-form-container" style={{ backgroundColor: `${props.config_options.background_color}` }}>
            <div className="form-title" >
                Formular de înscriere
            </div>
            <div className="form-row">
                <div className="form-row-label">
                    Echipa
                </div>
                <div className="form-row-inputs">
                    <Input
                        name="academy"
                        label="Numele echipei*"
                        value={academyName}
                        handleChange={(e) => setAcademyName(e.target.value)}
                        style={styleFocused1}
                        onFocus={() => {
                            setStyleFocused1({ 'border-bottom': `4px solid #dee0de` });
                            setError(false);
                            setSuccess(false);
                        }}
                        onBlur={() => {
                            setStyleFocused1({ 'border-bottom': `4px solid black` });
                        }}
                        halfWidth
                        data={props}
                    />
                    <SelectCustom
                        name="county"
                        label="Județul*"
                        placeholder=""
                        value={county}
                        options={options}
                        handleChange={(e) => { console.log(e); setCounty(e) }}
                        styles={customStyles}
                        onMenuOpen={() => {
                            setShrinkLabel(true);
                            setError(false);
                            setSuccess(false);
                        }}
                        onMenuClose={() => {
                            setShrinkLabel(false)
                        }}
                        halfWidth
                        shrink={shrinkLabel || county !== ""}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-row-label">
                    Persoană de contact
                </div>
                <div className="form-row-inputs">
                    <Input
                        name="person"
                        label="Nume*"
                        value={name}
                        style={styleFocused2}
                        onFocus={() => {
                            setStyleFocused2({ 'border-bottom': `4px solid #dee0de` });
                            setError(false);
                            setSuccess(false);
                        }}
                        onBlur={() => {
                            setStyleFocused2({ 'border-bottom': `4px solid black` });
                        }}
                        handleChange={(e) => setName(e.target.value)}
                        thirdWidth
                        data={props}
                    />
                    <Input
                        name="email"
                        label="Email*"
                        value={email}
                        style={styleFocused3}
                        onFocus={() => {
                            setStyleFocused3({ 'border-bottom': `4px solid #dee0de` });
                            setError(false);
                            setSuccess(false);
                        }}
                        onBlur={() => {
                            setStyleFocused3({ 'border-bottom': `4px solid black` });
                        }}
                        handleChange={(e) => setEmail(e.target.value)}
                        thirdWidth
                        data={props}
                    />
                    <Input
                        name="tel"
                        label="Telefon*"
                        value={tel}
                        style={styleFocused4}
                        onFocus={() => {
                            setStyleFocused4({ 'border-bottom': `4px solid #dee0de` });
                            setError(false);
                            setSuccess(false);
                        }}
                        onBlur={() => {
                            setStyleFocused4({ 'border-bottom': `4px solid black` });
                        }}
                        handleChange={(e) => setTel(e.target.value)}
                        thirdWidth
                        data={props}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-row-label">
                    Detalii suplimentare
                </div>
                <div className="form-row-inputs">
                    <Input
                        name="details"
                        label="Introdu mesajul"
                        value={details}
                        style={styleFocused5}
                        onFocus={() => {
                            setStyleFocused5({ 'border-bottom': `4px solid #dee0de` });
                            setSuccess(false);
                        }}
                        onBlur={() => {
                            setStyleFocused5({ 'border-bottom': `4px solid black` });
                        }}
                        handleChange={(e) => setDetails(e.target.value)}
                    />
                </div>
            </div>
            {error &&
                <div className="form-row error-text" style={{ color: "white" }}>
                    {"Completează câmpurile obligatorii!*"}
                </div>}
            {success &&
                <div className="form-row error-text" style={{ color: "white" }}>
                    {"Formularul de înscriere a fost trimis cu succes!"}
                </div>}
            <div className="form-sent-button">
                <Button
                    onClick={() => {
                        sendForm();
                    }}
                    theme={props.config_options.id_theme}
                    backgroundcolor={props.config_options.main_color}
                    color={props.config_options.header_color}
                    >
                    TRIMITE{" "}
                    <ArrowRightIcon
                        fill={props.config_options.header_color}
                        className="discover-more-button-icon"
                    />
                </Button>
            </div>
        </div>
    )
}