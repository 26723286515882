import React from 'react';
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import './App.css';
import axios from 'axios';
import Homepage from "./components/pages/Homepage";
import Schedule from "./components/pages/Schedule/Schedule";
import Rules from "./components/pages/Rules/Rules";
import Registration from "./components/pages/Registration/Registration";
import Matches from "./components/pages/Matches/Matches";
import Pusher from 'pusher-js';
import Loader from './components/reusable/Loader/Loader';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id_club: "",
      club_name: "",
      homepage_description: "",
      main_details_received: false,
      theme: "",
      main_color: "",
      footer_text_color: "",
      config_options: {},
      comp_details: {},
      all_data: {},
      teamsOptions: []
    };
    this.aboutRef = React.createRef();
    this.registrationRef = React.createRef();
    this.matchesRef = React.createRef();
    this.rankingRef = React.createRef();
    this.partnersRef = React.createRef();
  }

  scrollToAbout = () =>
    this.aboutRef.scrollIntoView({ behavior: "smooth", block: "start" });

  scrollToRegistration = () =>
    this.registrationRef.scrollIntoView({ behavior: "smooth", block: "start" });

  scrollToMatches = () =>
    this.matchesRef.scrollIntoView({ behavior: "smooth", block: "start" });

  scrollToRanking = () =>
    this.rankingRef.scrollIntoView({ behavior: "smooth", block: "start" });

  scrollToRules = () =>
    this.rulesRef.scrollIntoView({ behavior: "smooth", block: "start" });

  scrollToPartners = () =>
    this.partnersRef.scrollIntoView({ behavior: "smooth", block: "start" });

  componentDidMount = () => {

    // LIVE
    // const url = window.location.host.includes('localhost') ? "miroslava.thefootballbrain.ro" : window.location.host;
    // const url = window.location.host.includes('localhost') ? "cup.academiadidi.ro" : window.location.host;
    // const url = window.location.host.includes('localhost') ? "cupapietricica.thefootballbrain.ro" : window.location.host;
    const url = window.location.host.includes('localhost') ? "turneultigrilor.thefootballbrain.ro" : window.location.host;
    // const url = "cup.academiadidi.ro";
    // const url = "miroslava.thefootballbrain.ro";
    // const url = "goldcup.thefootballbrain.ro";
    // const url = "cupapietricica.thefootballbrain.ro";

    this.getSiteData(url);

    const pusher = new Pusher('ded84999dbba94030977', {
      cluster: 'eu'
    });

    const channel = pusher.subscribe('channel-update-matches');
    const this2 = this;
    channel.bind('event-edit-score', function (data) {
      if (data.id_competition == this2.state.comp_details.id_competition) {
        this2.getSiteData(url);
      }
    });

  };

  getSiteData = (url) => {
    axios.get(`site/get_competition_details?url=${url}`).then((res) => {
      let teamsOptions = [];
      res.data.comp_age_groups.forEach((elem, index) => {
        let item = {
          value: elem.id_competition,
          text: elem.competition_name
        }
        teamsOptions.push(item);
      });
      this.setState({
        id_club: res.data.id_club,
        club_name: res.data.club_name,
        homepage_description: res.data.description,
        main_details_received: true,
        theme: res.data.theme,
        main_color: res.data.main_color,
        config_options: res.data.config_options,
        comp_details: res.data.comp_details,
        all_data: res.data,
        teamsOptions: teamsOptions
      });
      const favicon = document.getElementById("favicon");
      favicon.href = `https://api.thefootballbrain.app/uploads/competition/logo/${res.data.comp_details.id_competition}.png`;
      document.title = res.data.comp_details.competition_name;
    });
  }

  render() {
    return (
      <Router>
        {this.state.main_details_received ?
          <div className='app'>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <Homepage
                    {...props}
                    id_club={this.state.id_club}
                    club_name={this.state.club_name}
                    description={
                      this.state.homepage_description
                    }
                    theme={this.state.theme}
                    main_color={this.state.main_color}
                    config_options={
                      this.state.config_options
                    }
                    comp_details={this.state.comp_details}
                    all_data={this.state.all_data}
                    teamsOptions={this.state.teamsOptions}
                  />
                )}
              />
              <Route
                exact
                path="/schedule"
                render={(props) => (
                  <Schedule
                    {...props}
                    id_club={this.state.id_club}
                    config_options={
                      this.state.config_options
                    }
                  />
                )}
              />
              <div ref={(ref) => (this.rulesRef = ref)}>
                <Rules
                  {...this.props}
                  id_club={this.state.id_club}
                  config_options={
                    this.state.config_options
                  }
                />
              </div>
              <Route
                exact
                path="/registration"
                render={(props) => (
                  <Registration
                    {...props}
                    id_club={this.state.id_club}
                    config_options={
                      this.state.config_options
                    }
                  />
                )}
              />
              <Route
                exact
                path="/matches"
                render={(props) => (
                  <Matches
                    {...props}
                    id_club={this.state.id_club}
                    config_options={
                      this.state.config_options
                    }
                  />
                )}
              />
            </Switch>
          </div>
          :
          <Loader />}
      </Router>
    );
  }
}

export default App;
