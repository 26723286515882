import React from "react"
import golgheter from "../../../assets/logo-jpg/gol.png"
import jucator from "../../../assets/logo-jpg/juc.png"
import portar from "../../../assets/logo-jpg/portar.png"
import "./SpecialPlayerCard.scss"

export default function SpecialPlayerCard(props) {

    const typesOfCard = [
        {
            title: "Golgheterul turneului",
            logo: golgheter
        },
        {
            title: "Jucatorul turneului",
            logo: jucator
        },
        {
            title: "Portarul turneului",
            logo: portar
        }
    ]

    return (
        <div className="card-container">
            <div className="card-content">
                <div className="category" style={{ borderRight: `1px solid ${props.config_options.article_paragraph_color}` }}>
                    <div className="category-logo">
                        <img src={typesOfCard[props.typeOfCard - 1].logo} />
                    </div>
                    <div className="category-title">
                        {props.player.prize_name}
                    </div>
                </div>
                <div className="player">
                    <div className="player-logo">
                        <img src={`https://api.thefootballbrain.app/uploads/competition/prizes/${props.player.id_competition_prize}.png`} />
                    </div>
                    <div className="player-name">
                        {props.player.player_name}
                    </div>
                </div>
            </div>
            <div className="card-number">
                {props.player.prize_number == 0 ? " " : props.player.prize_number}
            </div>
            <div className="card-number-border">
                <div className="border" style={{ borderBottom: `5px solid ${props.main_color}` }}>
                </div>
            </div>
        </div>
    )
}