import React from "react"
import { css } from "@emotion/css";
import "./MatchScheduleWidget.scss";
import moment from "moment";

class MatchScheduleWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            selectedOption: props.selectedOption,
            teamsOptions: props.teamsOptions,
            days: ["Duminica", "Luni", "Marti", "Miercuri", "Joi", "Vineri", "Sambata"]
        }
    };

    render() {
        return (
            <div className="schedule-widget-container">
                <div className="schedule-widget-title">
                    {"Program meciuri"}
                </div>
                <div className="schedule-widget-meniu">
                    {this.state.teamsOptions.map((item, index) => {
                        return <div
                            key={"match-" + index}
                            className={css`
                        color: ${item.value === this.props.selectedOption ? this.props.config_options.main_color : this.props.config_options.article_paragraph_color};
                        font-weight: ${item.value === this.props.selectedOption ? "bold" : "normal"} 
                        `}
                            onClick={() => {
                                this.props.handleSelectedOption(item.value);
                                this.setState({ selectedOption: item.value }, this.getTeams)
                            }}
                        >
                            <div className="schedule-widget-meniu-elem">
                                {item.text}
                                <div
                                    className="border-active"
                                    style={{
                                        borderBottom: item.value === this.props.selectedOption ? `5px solid ${this.props.main_color}` : '',
                                        paddingBottom: "5px",
                                    }}
                                >
                                </div>
                            </div>

                        </div>
                    })}
                </div>
                <div className="schedule-table">
                    <div className="schedule-table-top-row" style={{ backgroundColor: this.props.config_options.main_color, color: this.props.config_options.background_color }}>
                        <div className="col day" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                            {"Ziua"}
                        </div>
                        <div className="col time" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                            {"Ora"}
                        </div>
                        <div className="col location" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                            {"Locatia"}
                        </div>
                        <div className="col match" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                            {"Meciul"}
                        </div>
                        {this.props.all_data.comp_age_groups.map((elem1, index) => {
                            return elem1.id_competition == this.props.selectedOption && elem1.competition_type == "group-stage" ?
                                (<div key={"group-type-" + index} className="col group">
                                    {"Grupa"}
                                </div>) :
                                (elem1.id_competition == this.props.selectedOption && elem1.competition_type == "championship" ?
                                    <div key={"group-type-" + index} className="col group">
                                        {"Etapa"}
                                    </div> :
                                    null
                                )
                        })}
                    </div>
                    <div className="schedule-table-matches">
                        {this.props.all_data.comp_age_groups.map((elem1, index) => {
                            return elem1.id_competition == this.props.selectedOption ?
                                elem1.matches.length > 0 ?
                                    elem1.matches.map((elem, index) => {
                                        return <div className="shedule-match" key={"match-" + index}>
                                            <div className="col day" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                {this.state.days[moment(elem.match_date).day()] + ", " + moment(elem.match_date).format('DD.MM.YYYY')}
                                            </div>
                                            <div className="col time" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                {elem.match_date.split(" ")[1].substring(0, 5)}
                                            </div>
                                            <div className="col location" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                {elem.venue_name}
                                            </div>
                                            <div className="col match" style={{ borderRight: elem.id_competition_group > 0 || (elem.id_competition_group == 0 && elem.round.length > 0) ? `solid 1px ${this.props.config_options.article_paragraph_color}` : null }}>
                                                <div className="first-team">
                                                    <div className="logo-container" style={{ marginLeft: "5%" }}>
                                                        <img className="team-logo" src={`https://api.thefootballbrain.app/uploads/team_logos/${elem.id_team_home}logo.png`}
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null; // prevents looping
                                                                currentTarget.src = "https://api.thefootballbrain.app/uploads/placeholder_team.png";
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="team-name">
                                                        {elem1.teams_table.find(item => item.id_team === elem.id_team_home).team_name}
                                                    </div>
                                                </div>
                                                <div className="match-result">
                                                    {elem.team_home_goals == null ? "VS" : elem.team_home_goals + ":" + elem.team_away_goals}
                                                </div>
                                                <div className="second-team">
                                                    <div className="team-name">
                                                        {elem1.teams_table.find(item => item.id_team === elem.id_team_away).team_name}
                                                    </div>
                                                    <div className="logo-container" style={{ marginRight: "5%" }}>
                                                        <img className="team-logo" src={`https://api.thefootballbrain.app/uploads/team_logos/${elem.id_team_away}logo.png`}
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null; // prevents looping
                                                                currentTarget.src = "https://api.thefootballbrain.app/uploads/placeholder_team.png";
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {elem.id_competition_group > 0 && <div className="col group">
                                                <div className="group-circle" style={{ backgroundColor: this.props.config_options.main_color }}>
                                                    <div className="group-letter" style={{ color: this.props.config_options.buttons_text_color }}>{elem1.groups.find(item => item.id_competition_group === elem.id_competition_group).group_name}</div>
                                                </div>
                                            </div>}
                                            {elem.id_competition_group == 0 && elem.round.length > 0 && <div className="col after-group">
                                                {elem.round}
                                            </div>}
                                        </div>
                                    }) :
                                    <div style={{ textAlign: "center", fontSize: "19px" }}>Momentan, nu sunt meciuri programate.</div>
                                : null
                        })}
                    </div>
                </div>
                {/* {this.state.all_data.comp_age_groups.map((elem, index) => {
                    return elem.id_competition == this.state.selectedOption ? <SpecialPlayers prizes={elem.prizes} /> : <>AAA</>
                })} */}
                {/* <SpecialPlayers prizes={this.state.all_data.comp_age_groups.prizes} /> */}
                <div className="schedule-table-mobile">
                    <div className="schedule-table-matches">
                        {this.props.all_data.comp_age_groups.map((elem1, index) => {
                            return elem1.id_competition == this.props.selectedOption ?
                                elem1.matches.map((elem, index) => {
                                    return <div className="schedule-match" key={"match-mobile-" + index}>
                                        {elem.id_competition_group > 0 &&
                                            <div className="group" style={{ color: this.props.config_options.buttons_text_color, backgroundColor: this.props.config_options.main_color }}>
                                                {"Grupa " + elem1.groups.find(item => item.id_competition_group === elem.id_competition_group).group_name}
                                            </div>
                                        }
                                        {elem.id_competition_group == 0 && elem.round.length > 0 &&
                                            <div className="after-group" style={{ color: this.props.config_options.buttons_text_color, backgroundColor: this.props.config_options.main_color }}>
                                                {"Etapa: " + elem.round}
                                            </div>
                                        }
                                        <div className="match-details">
                                            <div className="first-team">
                                                <div className="team-name">
                                                    {elem1.teams_table.find(item => item.id_team === elem.id_team_home).team_name}
                                                </div>
                                                <div className="logo-container">
                                                    <img className="team-logo" src={`https://api.thefootballbrain.app/uploads/team_logos/${elem.id_team_home}logo.png`}
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = "https://api.thefootballbrain.app/uploads/placeholder_team.png";
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="match-result">
                                                {elem.team_home_goals == null ? "VS" : elem.team_home_goals + ":" + elem.team_away_goals}
                                            </div>
                                            <div className="second-team">
                                                <div className="logo-container">
                                                    <img className="team-logo" src={`https://api.thefootballbrain.app/uploads/team_logos/${elem.id_team_away}logo.png`}
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = "https://api.thefootballbrain.app/uploads/placeholder_team.png";
                                                        }}
                                                    />
                                                </div>
                                                <div className="team-name">
                                                    {elem1.teams_table.find(item => item.id_team === elem.id_team_away).team_name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="match-info">
                                            <div className="day">
                                                {this.state.days[moment(elem.match_date).day()] + ", " + moment(elem.match_date).format('DD.MM.YYYY')}
                                            </div>
                                            <div className="split-line" style={{ borderColor: `${this.props.config_options.article_paragraph_color}` }}></div>
                                            <div className="time" >
                                                {elem.match_date.split(" ")[1].substring(0, 5)}
                                            </div>
                                            <div className="split-line" style={{ borderColor: `${this.props.config_options.article_paragraph_color}` }}></div>
                                            <div className="location">
                                                {elem.venue_name}
                                            </div>
                                        </div>
                                    </div>
                                })
                                : null
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default MatchScheduleWidget;