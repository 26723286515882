import React from "react";
import "./Partners.scss"

export default function Partners(props) {

    const data = props.all_data;

    return (
        <div className="partners-container">
            <div className="title-container">
                {"Partenerii noștri"}
            </div>
            <div className="partners-list">
                {data.sponsors.map((elem, index) => {
                    return <div
                        className="partner-container"
                        style={{ borderRight: `0px solid ${props.config_options.article_paragraph_color}` }}
                        key={index}
                    >
                        <a href={elem.sponsor_website.length > 0 ? elem.sponsor_website : null} target="_blank" rel="noreferrer">
                            <img src={`https://api.thefootballbrain.app/uploads/competition/sponsors_logos/${elem.id_competition_sponsor}.png`} alt="logo-sponsor" />
                        </a>
                    </div>
                })}

            </div>
        </div>
    )
}