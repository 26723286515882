import React from "react";
import HomepageIntro from "../sections/HomepageIntro/HomepageIntro";
import AboutCompetition from "../sections/AboutCompetition/AboutCompetition";
import RegistrationForm from "../sections/RegistrationForm/RegistrationForm";
import RegisteredTeamsWidget from "../sections/RegisteredTeamsWidget/RegisteredTeamsWidget";
import MatchScheduleWidget from "../sections/MatchScheduleWidget/MatchScheduleWidget"
import GroupRankingWidget from "../sections/GroupRankingWidget/GroupRankingWidget";
import SpecialPlayers from "../sections/SpecialPlayerCard/SpecialPlayers";
import CompetitionRules from "../sections/CompetitionRules/CompetitionRules";
import Partners from "../sections/Partners/Partners";
import Footer from "../sections/Footer/Footer";
import LiveMatchesWidget from "../sections/LiveMatchesWidget/LiveMatchesWidget"

class Homepage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: props.teamsOptions[0].value,
        };
        this.homeRef = React.createRef();
        this.aboutRef = React.createRef();
        this.registrationRef = React.createRef();
        this.matchesRef = React.createRef();
        this.rankingRef = React.createRef();
        this.partnersRef = React.createRef();
    }

    scrollToHome = () =>
        this.homeRef.scrollIntoView({ behavior: "smooth", block: "start" });

    scrollToAbout = () =>
        this.aboutRef.scrollIntoView({ behavior: "smooth", block: "start" });

    scrollToRegistration = () =>
        this.registrationRef.scrollIntoView({ behavior: "smooth", block: "start" });

    scrollToMatches = () =>
        this.matchesRef.scrollIntoView({ behavior: "smooth", block: "start" });

    scrollToRanking = () =>
        this.rankingRef.scrollIntoView({ behavior: "smooth", block: "start" });

    scrollToRules = () =>
        this.rulesRef.scrollIntoView({ behavior: "smooth", block: "start" });

    scrollToPartners = () =>
        this.partnersRef.scrollIntoView({ behavior: "smooth", block: "start" });

    handleSelectedOption = (option) => {
        this.setState({
            selectedOption: option
        });
    }

    render() {
        return (
            <div style={{ fontFamily: "Helvetica Neue" }}>
                <div ref={(ref) => (this.homeRef = ref)}>
                    <HomepageIntro
                        id_club={this.props.id_club}
                        club_name={this.props.club_name}
                        description={this.props.description}
                        theme={this.props.theme}
                        main_color={this.props.main_color}
                        config_options={this.props.config_options}
                        scrollToRules={this.scrollToRules}
                        scrollToHome={this.scrollToHome}
                        scrollToRegistration={this.scrollToRegistration}
                        scrollToMatches={this.scrollToMatches}
                        scrollToRanking={this.scrollToRanking}
                        scrollToPartners={this.scrollToPartners}
                        data={this.props}
                        selectedOption={this.state.selectedOption}
                        handleSelectedOption={this.handleSelectedOption}
                    />
                </div>
                {this.props.all_data.live_matches.length > 0 && <LiveMatchesWidget {...this.props} />}
                <div ref={(ref) => (this.matchesRef = ref)}>
                    <MatchScheduleWidget
                        {...this.props}
                        selectedOption={this.state.selectedOption}
                        handleSelectedOption={this.handleSelectedOption}
                    />
                </div>
                <div ref={(ref) => (this.rankingRef = ref)}>
                    <GroupRankingWidget
                        {...this.props}
                        selectedOption={this.state.selectedOption}
                        handleSelectedOption={this.handleSelectedOption}
                    />
                </div>
                {/* {this.props.comp_details.show_prizes == 1 && (
                    <SpecialPlayers {...this.props} />
                )} */}
                <AboutCompetition
                    scrollToRules={this.scrollToRules}
                    data={this.props}
                />
                {this.props.comp_details.show_registration == 1 ? (
                    <>
                        <div ref={(ref) => (this.registrationRef = ref)}>
                            <RegistrationForm {...this.props} />
                        </div>
                        <RegisteredTeamsWidget {...this.props}
                            data={this.props}
                            selectedOption={this.state.selectedOption}
                            handleSelectedOption={this.handleSelectedOption}
                        />
                    </>
                ) : <div ref={(ref) => (this.registrationRef = ref)}>
                    <RegisteredTeamsWidget {...this.props}
                        data={this.props}
                        selectedOption={this.state.selectedOption}
                        handleSelectedOption={this.handleSelectedOption}
                    />
                </div>
                }
                <div ref={(ref) => (this.rulesRef = ref)}>
                    <CompetitionRules {...this.props} />
                </div>
                <div ref={(ref) => (this.partnersRef = ref)}>
                    <Partners {...this.props} />
                </div>
                <Footer {...this.props} data={this.props} />
            </div >
        );
    }
}

export default Homepage;
