import React from "react"
import { css } from "@emotion/css";
import "./GroupRankingWidget.scss";
import SpecialPlayers from "../SpecialPlayerCard/SpecialPlayers";

class GroupRankingWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            groupRanking: [],
            selectedOption: props.selectedOption,
            teamsOptions: props.teamsOptions,
        }
    };

    render() {

        return (
            <div className="group-ranking-widget-container">
                <div className="group-ranking-widget-title">
                    {this.props.all_data.comp_details.competition_name + " " + new Date().getFullYear()}
                </div>
                {this.props.all_data.comp_details.show_groups == 1 ?
                    <>
                        <div className="group-ranking-widget-meniu">
                            {this.state.teamsOptions.map((item, index) => {
                                return <div
                                    className={css`
                        color: ${item.value === this.props.selectedOption ? this.props.config_options.main_color : this.props.config_options.article_paragraph_color};
                        font-weight: ${item.value === this.props.selectedOption ? "bold" : "normal"} 
                        `}
                                    onClick={() => {
                                        this.props.handleSelectedOption(item.value);
                                        this.setState({ selectedOption: item.value }, this.getGroupRanking);
                                    }}
                                    key={"elem-meniu" + index}
                                >
                                    <div className="group-ranking-widget-meniu-elem">
                                        {item.text}
                                        <div
                                            className="border-active"
                                            style={{
                                                borderBottom: item.value === this.props.selectedOption ? `5px solid ${this.props.main_color}` : '',
                                                paddingBottom: "5px",
                                            }}
                                        >
                                        </div>
                                    </div>

                                </div>
                            })}
                        </div>
                        {this.props.all_data.comp_age_groups.map((elem, index) => {
                            return elem.id_competition == this.props.selectedOption && elem.competition_type == "championship" ? (
                                <div className="group-ranking-container">
                                    <div className="group-name" style={{ color: this.props.config_options.header_lines_color }}>
                                        {" "}
                                    </div>
                                    <div className="group-ranking-table">
                                        <div className="group-ranking-table-top-row" style={{ backgroundColor: this.props.config_options.main_color, color: this.props.config_options.background_color }}>
                                            <div className="col pos" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                Pozitia
                                            </div>
                                            <div className="col team" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                Echipa
                                            </div>
                                            <div className="col nr-match" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                Meciuri
                                            </div>
                                            <div className="col nr-win" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                Victorii
                                            </div>
                                            <div className="col nr-draw" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                Egaluri
                                            </div>
                                            <div className="col nr-loss" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                Infrangeri
                                            </div>
                                            <div className="col goal-diff" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                Golaveraj
                                            </div>
                                            <div className="col point">
                                                Punctaj
                                            </div>
                                        </div>
                                        <div className="group-ranking-lines">
                                            {elem.teams_table.map((elem, index) => {
                                                return (
                                                    <div className="ranking-line" style={{ borderLeft: `solid 10px ${index + 1 < 3 ? this.props.main_color : "transparent"}` }}>
                                                        <div className="col pos" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                            <div className="index-line">
                                                                {(index + 1) + "."}
                                                            </div>
                                                        </div>
                                                        <div className="col team" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                            <div className="team-logo">
                                                                <img
                                                                    src={`https://api.thefootballbrain.app/uploads/team_logos/${elem.team_logo}`}
                                                                    onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null; // prevents looping
                                                                        currentTarget.src = "https://api.thefootballbrain.app/uploads/placeholder_team.png";
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="team-name">
                                                                {elem.team_name}
                                                            </div>
                                                        </div>
                                                        <div className="col nr-match" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                            {elem.matches_played}
                                                        </div>
                                                        <div className="col nr-win" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                            {elem.matches_won}
                                                        </div>
                                                        <div className="col nr-draw" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                            {elem.matches_draw}
                                                        </div>
                                                        <div className="col nr-loss" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                            {elem.matches_lost}
                                                        </div>
                                                        <div className="col goal-diff" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                            {elem.goals_scored + " : " + elem.goals_conceeded}
                                                        </div>
                                                        <div className="col point">
                                                            {elem.points}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>

                                    <div className="group-ranking-table-mobile" style={{ marginTop: "15px" }}>
                                        <div className="group-ranking-table-top-row" style={{ backgroundColor: this.props.config_options.main_color, color: this.props.config_options.background_color }}>
                                            <div className="col team" >
                                                Echipa
                                            </div>
                                            <div className="col nr-match">
                                                M
                                            </div>
                                            <div className="col nr-win" >
                                                V
                                            </div>
                                            <div className="col nr-draw" >
                                                E
                                            </div>
                                            <div className="col nr-loss" >
                                                I
                                            </div>
                                            <div className="col goal-diff" >
                                                Gol
                                            </div>
                                            <div className="col point">
                                                PT
                                            </div>
                                        </div>
                                        <div className="group-ranking-lines">
                                            {elem.teams_table.map((elem2, index) => {
                                                return (
                                                    <div className="ranking-line" style={{ borderLeft: `solid 5px ${index + 1 < 3 ? this.props.main_color : "transparent"}` }}>
                                                        <div className="col team" >
                                                            <div className="logo-container">
                                                                <img
                                                                    className="team-logo" src={`https://api.thefootballbrain.app/uploads/team_logos/${elem2.team_logo}`}
                                                                    onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null; // prevents looping
                                                                        currentTarget.src = "https://api.thefootballbrain.app/uploads/placeholder_team.png";
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="team-name">
                                                                {elem2.team_name}
                                                            </div>
                                                        </div>
                                                        <div className="col nr-match" >
                                                            {elem2.matches_played}
                                                        </div>
                                                        <div className="col nr-win" >
                                                            {elem2.matches_won}
                                                        </div>
                                                        <div className="col nr-draw">
                                                            {elem2.matches_draw}
                                                        </div>
                                                        <div className="col nr-loss" >
                                                            {elem2.matches_lost}
                                                        </div>
                                                        <div className="col goal-diff" >
                                                            {elem2.goals_scored + " : " + elem2.goals_conceeded}
                                                        </div>
                                                        <div className="col point">
                                                            {elem2.points}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )
                                : null
                        })}
                        {this.props.all_data.comp_age_groups.map((elem, index) => {
                            return elem.id_competition == this.props.selectedOption ? (
                                <>
                                    {elem.groups.map((item, index) => {
                                        var i = 0;
                                        var i2 = 0;
                                        return <>
                                            <div className="group-ranking-container" key={"group" + index}>
                                                <div className="group-name" style={{ color: this.props.config_options.header_lines_color }}>
                                                    {"Grupa " + item.group_name}
                                                </div>
                                                <div className="group-ranking-table">
                                                    <div className="group-ranking-table-top-row" style={{ backgroundColor: this.props.config_options.main_color, color: this.props.config_options.background_color }}>
                                                        <div className="col pos" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                            Pozitia
                                                        </div>
                                                        <div className="col team" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                            Echipa
                                                        </div>
                                                        <div className="col nr-match" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                            Meciuri
                                                        </div>
                                                        <div className="col nr-win" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                            Victorii
                                                        </div>
                                                        <div className="col nr-draw" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                            Egaluri
                                                        </div>
                                                        <div className="col nr-loss" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                            Infrangeri
                                                        </div>
                                                        <div className="col goal-diff" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                            Golaveraj
                                                        </div>
                                                        <div className="col point">
                                                            Punctaj
                                                        </div>
                                                    </div>
                                                    <div className="group-ranking-lines">
                                                        {elem.teams_table.map((elem, index) => {
                                                            if (item.id_competition_group == elem.id_competition_group) i = i + 1;
                                                            return item.id_competition_group == elem.id_competition_group ? (
                                                                <div className="ranking-line" style={{ borderLeft: `solid 10px ${i < 3 ? this.props.main_color : "transparent"}` }}>
                                                                    <div className="col pos" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                                        <div className="index-line">
                                                                            {i + "."}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col team" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                                        <div className="team-logo">
                                                                            <img
                                                                                src={`https://api.thefootballbrain.app/uploads/team_logos/${elem.team_logo}`}
                                                                                onError={({ currentTarget }) => {
                                                                                    currentTarget.onerror = null; // prevents looping
                                                                                    currentTarget.src = "https://api.thefootballbrain.app/uploads/placeholder_team.png";
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="team-name">
                                                                            {elem.team_name}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col nr-match" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                                        {elem.matches_played}
                                                                    </div>
                                                                    <div className="col nr-win" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                                        {elem.matches_won}
                                                                    </div>
                                                                    <div className="col nr-draw" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                                        {elem.matches_draw}
                                                                    </div>
                                                                    <div className="col nr-loss" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                                        {elem.matches_lost}
                                                                    </div>
                                                                    <div className="col goal-diff" style={{ borderRight: `solid 1px ${this.props.config_options.article_paragraph_color}` }}>
                                                                        {elem.goals_scored + " : " + elem.goals_conceeded}
                                                                    </div>
                                                                    <div className="col point">
                                                                        {elem.points}
                                                                    </div>
                                                                </div>
                                                            ) : null
                                                        })}
                                                    </div>
                                                </div>

                                                <div className="group-ranking-table-mobile">
                                                    <div className="group-ranking-table-top-row" style={{ backgroundColor: this.props.config_options.main_color, color: this.props.config_options.background_color }}>
                                                        <div className="col team" >
                                                            Echipa
                                                        </div>
                                                        <div className="col nr-match">
                                                            M
                                                        </div>
                                                        <div className="col nr-win" >
                                                            V
                                                        </div>
                                                        <div className="col nr-draw" >
                                                            E
                                                        </div>
                                                        <div className="col nr-loss" >
                                                            I
                                                        </div>
                                                        <div className="col goal-diff" >
                                                            Gol
                                                        </div>
                                                        <div className="col point">
                                                            PT
                                                        </div>
                                                    </div>
                                                    <div className="group-ranking-lines">
                                                        {elem.teams_table.map((elem2, index) => {
                                                            if (item.id_competition_group == elem2.id_competition_group) i2 = i2 + 1;
                                                            return item.id_competition_group == elem2.id_competition_group ? (
                                                                <div className="ranking-line" style={{ borderLeft: `solid 5px ${i2 < 3 ? this.props.main_color : "transparent"}` }}>
                                                                    <div className="col team" >
                                                                        <div className="logo-container">
                                                                            <img
                                                                                className="team-logo" src={`https://api.thefootballbrain.app/uploads/team_logos/${elem2.team_logo}`}
                                                                                onError={({ currentTarget }) => {
                                                                                    currentTarget.onerror = null; // prevents looping
                                                                                    currentTarget.src = "https://api.thefootballbrain.app/uploads/placeholder_team.png";
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="team-name">
                                                                            {elem2.team_name}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col nr-match" >
                                                                        {elem2.matches_played}
                                                                    </div>
                                                                    <div className="col nr-win" >
                                                                        {elem2.matches_won}
                                                                    </div>
                                                                    <div className="col nr-draw">
                                                                        {elem2.matches_draw}
                                                                    </div>
                                                                    <div className="col nr-loss" >
                                                                        {elem2.matches_lost}
                                                                    </div>
                                                                    <div className="col goal-diff" >
                                                                        {elem2.goals_scored + " : " + elem2.goals_conceeded}
                                                                    </div>
                                                                    <div className="col point">
                                                                        {elem2.points}
                                                                    </div>
                                                                </div>
                                                            ) : null
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    })}
                                </>

                            ) : null
                        })}
                    </> :
                    <div className="no-groups-content">
                        {"Repartizarea pe grupe va apărea în curând."}
                    </div>
                }
                {this.props.all_data.comp_age_groups.map((elem, index) => {
                    return elem.id_competition == this.props.selectedOption && elem.show_prizes === "1" ? <SpecialPlayers {...this.props} prizes={elem.prizes} /> : null
                })}
            </div>
        )
    }
}

export default GroupRankingWidget;