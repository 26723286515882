import React from "react";
import "./Footer.scss"

export default function Footer(props) {

    const { data } = props;

    return (
        <div className="footer-container" style={{ backgroundColor: `${props.config_options.background_color}`, }}>
            <div className="footer-club-info">
                <div className="logo-footer">
                    <img src={`https://api.thefootballbrain.app/uploads/club_logos/${props.id_club}.png`} />
                </div>
                <div className="academy-name">
                    {`by ${data.club_name.toUpperCase()}`}
                </div>
                <div className="contact-info">
                    <div className="tel">
                        {data.comp_details.competition_phone}
                    </div>
                    <div className="email">
                        {data.comp_details.competition_email}
                    </div>
                </div>
            </div>

            <div
                className="develop-info"
                style={{ backgroundColor: props.config_options.footer_line_color, color: props.config_options.footer_brandweb_color, borderRadius: props.theme == "2" ? "10px" : "0" }}
            >
                <div className="anpc" style={{ cursor: "pointer" }}>
                    <a
                        style={{ textDecoration: "none", color: props.config_options.footer_brandweb_color }}
                        href="https://anpc.ro/"
                        target="_blank"
                    >
                        {"ANPC"}
                    </a>
                </div>
                <div className="tfb" style={{ cursor: "pointer" }}>
                    <a
                        style={{ textDecoration: "none", color: props.config_options.footer_brandweb_color }}
                        href="https://tfb.ai/ro"
                        target="_blank"
                    >
                        {"Powered by The Football Brain"}
                    </a>
                </div>
            </div>
            <div
                className="develop-info-mobile"
                style={{ backgroundColor: props.config_options.footer_line_color, color: props.config_options.footer_brandweb_color, borderRadius: props.theme == "2" ? "10px" : "0" }}
            >
                <div className="line-one">
                    <div className="anpc">
                        <a
                            style={{ textDecoration: "none", color: props.config_options.footer_brandweb_color }}
                            href="https://anpc.ro/"
                            target="_blank"
                        >
                            {"ANPC"}
                        </a>
                    </div>
                </div>
                <div className="tfb">
                    <a
                        style={{ textDecoration: "none", color: props.config_options.footer_brandweb_color }}
                        href="https://tfb.ai/ro"
                        target="_blank"
                    >
                        {"Powered by The Football Brain"}
                    </a>
                </div>
            </div>
        </div>
    )
}